.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
}

.App-header,
.App-footer {
  background-color: var(--bar-background-colour);
  color: var(--bar-foreground-colour);
}

.App-header {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--large-font-size);
}

.App-body {
  flex-grow: 2;
  padding: 20px 20px;
  background-color: var(--body-background-colour);
  color: var(--body-foreground-colour);
  font-size: var(--normal-font-size);
}

.App-footer {
  font-size: var(--normal-font-size);
}

.App-link,
a {
  color: var(--link-colour);
}

/** App Header */
.App-header h1 {
  font-size: var(--large-font-size);
}



